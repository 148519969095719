/*===> BEGIN AUDIT FORM <===*/
.audit-form{
	display: flex;
	flex-direction: column;
	justify-content: center;
	height: 100%;
	padding: 0 65px;
	@include linear-gradient(135deg, $gradient_color_from, $gradient_color_to);

	h3, .h3{
		margin-bottom: 75px;
		font-weight: 700;
		text-align: center;
		color: #fff;
	}

	input{
		margin-bottom: 20px;
	}

	button{
		margin-top: 60px;
		padding: 13px 35px;
	}

	@include breakpoint(medium){
		padding: 40px;
		align-items: center;
		h3, .h3{
			margin-bottom: 40px;
		}

		input{
			width: 100%;
			max-width: 400px;
		}

		button{
			width: 100%;
			max-width: 400px;
			margin-top: 20px;
		}
	}

	@include breakpoint(small){
		margin-left: -15px;
		margin-right: -15px;
	}

	@include breakpoint(mobile){
		padding: 40px 15px;

		h3, .h3{
			margin-bottom: 30px;
		}

		input{
			margin-bottom: 15px;
		}

		button{
			margin-top: 15px;
		}
	}
}
/*===> END AUDIT FORM <===*/