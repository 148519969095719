/*===> BEGIN BACK CALL <===*/
.back-call-wrap{
	position: fixed;
	z-index: 1000;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	transition: all .5s;
	visibility: hidden;
	opacity: 0;
	&.active-popup{
		visibility: visible;
		opacity: 1;
	}

	.back-call{
		position: relative;
		padding: 60px 100px;
		border-radius: 25px;
		@include linear-gradient(135deg, $gradient_color_from, $gradient_color_to);
		.close-popup{
			position: absolute;
			top: 20px;
			right: 21px;
			margin: 0;
			font-size: 20px;
			font-weight: 400;
			line-height: 1em;
			color: #fff;
			cursor: pointer;
		}

		h3, .h3{
			font-weight: 700;
			text-align: center;
			color: #fff;
		}

		.back-call-form{
			input{
				width: 100%;
			}

			button{
				width: 100%;
				padding: 13px 20px;
			}
		}
	}

	@include breakpoint(small){
		.back-call{
			padding: 50px;
		}
	}

	@include breakpoint(extra-small){
		width: 100%;
		.back-call{
			padding: 40px 20px;

			.back-call-form{
				input{
					display: block;
					max-width: 400px;
					margin-left: auto;
					margin-right: auto;
					margin-bottom: 20px;
				}

				button{
					display: block;
					max-width: 400px;
					margin-left: auto;
					margin-right: auto;
				}
			}
		}
	}
}
/*===> END BACK CALL <===*/