/*===> BEGIN BLOCK FORM <===*/
.block-form{
	position: relative;
	z-index: 2;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	width: 100%;
	max-width: 945px;
	margin-left: auto;
	margin-right: auto;
	input{
		width: 100%;
		max-width: 260px;
		margin-right: 30px;
	}

	button{
		display: block;
		width: 100%;
		max-width: 360px;
	}

	@include breakpoint(small){
		flex-direction: column;
		align-items: center;
		input{
			max-width: 360px;
			margin-right: 0;
			margin-bottom: 30px;
		}

		button{
			padding: 15px 15px;
		}
	}
}
/*===> END BLOCK FORM <===*/