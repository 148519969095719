/*===> BEGIN THANK POPUP <===*/
.thank-popup{
	position: fixed;
	z-index: 1000;
	top: 50%;
	left: 50%;
	overflow-y: auto;
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	max-height: 100%;
	max-width: 560px;
	padding: 100px 20px;
	transform: translate(-50%, -50%);
	transition: all .25s;
	visibility: hidden;
	opacity: 0;
	@include linear-gradient(135deg, $gradient_color_from, $gradient_color_to);
	&.active-popup{
		visibility: visible;
		opacity: 1;
	}

	.close-popup{
		position: absolute;
		top: 5px;
		right: 5px;
		margin: 0;
		font-size: 20px;
		font-weight: 400;
		line-height: 20px;
		color: #fff;
		cursor: pointer;
	}

	img{
		margin-bottom: 50px;
	}

	h3, .h3{
		max-width: 255px;
		margin: 0;
		font-weight: 700;
		text-align: center;
		color: #fff;
	}
}
/*===> END THANK POPUP <===*/