/*===> BEGIN LAPTOP SLIDER <===*/
.laptop-slider-wrap{
	position: relative;
	height: 440px;
	min-width: 755px;
	padding: 40px 100px;
	background: url(../img/laptop.png) no-repeat center;
	background-size: cover;
	.leptop-slider-desc-wrap{
		position: absolute;
		top: 50%;
		right: 0;
		transform: translateY(-50%);
		.leptop-result-slider{
			width: 150px;
			height: 150px;
			margin-top: 30px;
			border-radius: 50%;
			overflow: hidden;
			background: $third_color;
			&:nth-child(1){
				margin-top: 0;
			}

			div{
				height: inherit;
			}

			.result-card{
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				padding: 0 16px;
				h6, .h6{
					width: 100%;
					margin-bottom: 5px;
					text-align: center;
					color: #fff;
				}

				h4, .h4{
					margin-bottom: 0;
					text-align: center;
					white-space: nowrap;
					color: #fff;
				}
			}
		}
	}

	.laptop-slider{
		height: 100%;
		img{
			height: 100%;
		}
	}

	.laptop-bottom{
		margin-top: 50px;
		color: #87858b;
	}

	@include breakpoint(medium){
		height: 355px;
		min-width: 590px;
		margin-right: 30px;
		padding: 30px 75px;
	}

	@include breakpoint(small){
		height: 415px;
		margin-bottom: 30px;
		margin-right: 0;
	}

	@include breakpoint(extra-small){
		display: none;
	}
}

.client-result-slider-wrap{
	padding-left: 45px;

	h3, .h3{
		text-align: center;
		margin-bottom: 10px;
	}

	a{
		display: block;
		margin-bottom: 50px;
		text-align: center;
		font-family: $header_font;
		font-weight: 400;
		font-size: 18px;
		&:hover{
			text-decoration: underline;
		}
	}

	.client-mob-info{
		display: none;
		.mob-info-wrap{
			display: flex;
			align-items: center;
			justify-content: center;
			width: 100%;
			margin-bottom: 20px;
			img{
				margin-right: 10px;
			}

			h5, .h5{
				margin-bottom: -5px;
				font-size: 24px;
			}

			p{
				margin-bottom: 0;
			}
		}
	}

	.client-main-info{
		h6, .h6{
			margin-bottom: 0;
			i{
				margin-right: 10px;
				font-size: 20px;
				color: $second_color;
			}
		}

		>h6, >.h6{
			margin-bottom: 10px;
		}

		p{
			margin-bottom: 0;
		}

		.client-info-wrap{
			display: flex;
			align-items: flex-end;
			margin: 40px 0;
			p{
				width: 50%;
			}
		}
	}

	@include breakpoint(medium){
		padding-left: 0;

		.client-main-info{
			.client-info-wrap{
				margin: 30px 0;
			}
		}

		a{
			margin-bottom: 30px;
		}
	}

	@include breakpoint(small){
		max-width: 400px;
		margin-left: auto;
		margin-right: auto;
	}

	@include breakpoint(extra-small){
		a{
			margin-bottom: 20px;
		}

		.client-mob-info{
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			margin-bottom: 10px;
		}

		h3, .h3{
			font-size: 24px;
			color: $second_color;
		}

		.client-main-info{
			display: flex;
			flex-direction: column;
			text-align: center;
			.client-info-wrap{
				display: flex;
				align-items: flex-end;
				margin: 20px 0 30px;
				h6, .h6{
					font-size: 20px;
				}
				
				p{
					width: 50%;
				}
			}
		}
	}
}
/*===> END LAPTOP SLIDER <===*/