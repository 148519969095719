/*===> BEGIN BUTTONS <===*/
button,
.button,
.btn{
	position: relative;
	overflow: hidden;
	padding: 18px 35px;
	border: none;
	border-radius: 10px;
	background: $third_color;
	box-shadow: 0px 0px 25px 0px rgba(224, 44, 41, 0.45);
	font-family: $header_font;
	font-weight: 500;
	font-size: 20px;
	text-align: center;
	text-decoration: none !important;
	color: #fff;
	outline: none;
	transition: all .3s;

	&:hover{
		opacity: 1;
		box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.45);
		background: #fff;
		color: #000;
	}

	&.type_second{
		background: $second_color;
		box-shadow: 0px 0px 18px 0px rgba(18, 134, 218, 0.45);
		font-weight: 500;
		&:hover{
			background: #fff;
			color: #000;
		}
	}

	&.type_third{
		padding: 10px 15px;
		border: 1px solid  #fff;
		background-color: transparent;
		box-shadow: none;
		font-weight: 500;
		color: #fff;
		&:hover{
			background: #fff;
			color: #000;
		}
	}

	&.rocket {
		display: flex;
		align-items: center;
		&:hover {
			.img {
				background-position: 188px -4px;
			}
		}
		.img {
			display: block;
			width: 20px;
			min-width: 20px;
			height: 21px;
			background-position: 188px -34px;
		}
	}

	i{
		margin-right: 10px;
	}
}


/*===> END BUTTONS <===*/
