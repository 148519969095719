/*===> BEGIN TARIF <===*/
.wrap-tarif{
	width: 100%;
	max-width: 1470px;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 100px;
	h2, .h2{
		margin-bottom: 70px;
		padding: 0 15px;
		text-align: center;
		text-transform: uppercase;
	}

	.slide{
		padding: 0 15px;
	}

	.tarif-card{
		overflow: hidden;
		width: 263px;
		margin-left: auto;
		margin-right: auto;
		border-radius: 20px;
		box-shadow: 0px 0px 18px 0px rgba(0, 0, 0, 0.09);
		.tarif-head{
			position: relative;
			padding: 25px 10px 70px;
			@include linear-gradient(135deg, $gradient_color_from, $gradient_color_to);
			
			p{
				margin-bottom: 0;
				font-family: $header_font;
				font-weight: 700;
				font-size: 17px;
				text-transform: uppercase;
				text-align: center;
				color: #fff;
			}

			.tarif-logo{
				position: absolute;
				bottom: 0;
				left: 50%;
				z-index: 10;
				display: flex;
				align-items: center;
				justify-content: center;
				width: 100px;
				height: 100px;
				border-radius: 50%;
				background: #fff;
				box-shadow: 0px 0px 18px 0px rgba(0, 0, 0, 0.09);
				transform: translate(-50%, 50%);
				img{
					height: 60px;
				}
			}
		}

		.tarif-desc{
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			height: 100%;
			align-items: center;
			padding: 70px 20px 50px;
			text-align: center;
			h5, .h5{
				margin-bottom: 25px;
				text-align: center;
				color: $second_color;
			}

			p{
				width: 100%;
				margin-bottom: 30px;
			}

			.button{
				display: block;
				width: 100%;
				max-width: 160px;
				padding: 10px 15px;
			}
		}
	}

	@include breakpoint(extra-small){
		h2, .h2{
			margin-bottom: 50px;
		}
		
		.tarif-card{
			width: 100%;
			max-width: 290px;
		}
	}
}
/*===> END TARIF <===*/
