/*===> BEGIN NAVIGATION <===*/
.navigation{
	ul{
		display: flex;
		margin: 0;
		li{
			padding: 0 15px;
			margin: 0;
			&.close-menu{
				display: none;
			}

			&::before{
				display: none;
			}

			a{
				position: relative;
				padding: 48px 0;
				text-align: center;
				text-decoration: none;
				&::after{
					content: '';
					position: absolute;
					bottom: 0;
					left: 50%;
					width: 0;
					height: 5px;
					background: $main_color;
					transition: all .3s;
				}

				&:focus{
					color: #000;
				}

				&:hover{
					&::after{
						left: 0;
						width: 100%;
					}
				}

				&.active{
					&::after{
						left: 0;
						width: 100%;
					}
				}
			}
		}
	}

	@include breakpoint(medium){
		ul{
			li a{
				padding: 35px 0;
			}
		}
	}

	@include breakpoint(small){
		&::after{
			content: '';
			position: fixed;
			top: 0;
			right: 0;
			z-index: 99;
			width: 0;
			height: 100vh;
			background-color: transparent;
			transition: background .5s;
		}
		&.active-menu{
			&::after{
				width: 100vw;
				background: rgba(0,0,0, .5);
			}
			ul{
				transform: translateX(0%);
			}
		}
		ul{
			position: fixed;
			right: 0;
			top: 0;
			z-index: 1000;
			overflow: auto;
			width: 100%;
			height: 100vh;
			max-width: 400px;
			background: #fff;
			flex-direction: column;
			align-items: center;
			padding-top: 25px;
			transform: translateX(100%);
			transition: all .5s;
			li{
				&.close-menu{
					position: absolute;
					top: 15px;
					right: 15px;
					display: block;
					padding: 0;
					.img{
						display: block;
						width: 18px;
						min-width: 18px;
						height: 26px;
						min-height: 26px;
						background-position: 42px -47px;
					}
				}
				a{
					padding: 20px 0;
				}
			}
		}
	}
}
/*===> END NAVIGATION <===*/