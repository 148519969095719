/*===> BEGIN CLIENT <===*/
.client-wrap{
	padding: 100px 0;
	.slick-arrow{
		position: absolute;
		z-index: 10;
		top: 50%;
		padding: 0;
		width: 25px;
		height: 15px;
		overflow: visible;
		background-color: transparent;
		box-shadow: none !important;
		font-size: 0;
		line-height: 0;
		background-image: url(../img/eckit.svg);
		-webkit-background-size: auto;
		background-size: auto;
	
		&.slick-prev{
			left: 0;
			background-position: 191px -62px;
		}
	
		&.slick-next{
			right: 0;
			background-position: 191px -79px;
		}
	}
	
	.client-head{
		margin-bottom: 70px;
		text-align: center;
		h2, .h2{
			margin-bottom: 20px;
			text-transform: uppercase;
		}

		h6, .h6{
			margin-bottom: 0;
		}
	}

	.client-slider{
		margin-bottom: 50px;
		.client-slide-inner{
			display: flex;
		}
	}

	.wrap-client-slide{
		width: 100%;
	}

	.button{
		display: block;
		margin-left: auto;
		margin-right: auto;
		max-width: 360px;
	}

	.slick-arrow{
		top: auto;
		bottom: 100px;

		&.slick-prev{
			left: auto;
			right: 235px;
		}

		&.slick-next{
			right: 155px;
		}
	}

	@include breakpoint(medium){
		.slick-arrow{
			top: auto;
			bottom: 90px;
		}
	}

	@include breakpoint(small){
		.client-slider{
			.client-slide-inner{
				flex-direction: column;
				align-items: center;
			}
		}

		.slick-arrow{
			bottom: -25px;
			&.slick-prev{
				left: auto;
				right: auto;
				left: 40%;
			}

			&.slick-next{
				right: 40%;
			}
		}

		.laptop-bottom {
			margin-top: 20px;
			text-align: center;
			color: #87858b;
		}
	}

	@include breakpoint(extra-small) {
		.slick-arrow{
			top: auto;
			bottom: -25px;
		}
	}

	@include breakpoint(mobile) {
		.slick-arrow{
			&.slick-prev{
				left: 35%;
			}

			&.slick-next{
				right: 35%;
			}
		}
	}
}

.laptop-slider-control{
	display: flex;
	align-items: center;
	justify-content: center;
	p{
		margin-bottom: 0;
		cursor: pointer;
		&:first-child{
			margin-right: 30px;
		}

		&:last-child{
			margin-left: 30px;
		}

		i{
			font-size: 28px;
			color: $second_color;
		}
	}
}
/*===> END CLIENT <===*/