/*===> BEGIN ORDER CALL <===*/
.order-call{
	position: fixed;
	z-index: 100;
	bottom: 30px;
	left: 30px;
	width: 80px;
	height: 80px;
	border-radius: 50%;
	cursor: pointer;
	transition: all .2s;

	&:hover{
		box-shadow: 0px 1px 3px 1px $main_color;
		.order-call-card{
			.front{
				animation-name: none;
				transform: translate(-50%, -50%) rotateY(0);
			}

			.back{
				animation-name: none;
				transform: translate(-50%, -50%) rotateY(180deg);
			}
		}
	}

	&:active{
		box-shadow: none;
		.order-call-card{
			transition: all .2s;
			.front{
				box-shadow: inset 0px 2px 2px 1px $main_color;
			}
		}
	}

	.order-call-card{
		position: relative;
		width: 80px;
		height: 80px;
		border-radius: 50%;
		background: $main_color;
		@include linear-gradient(135deg, $gradient_color_from, $gradient_color_to);

		&::before{
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			border-radius: 50%;
			border: 1px solid $main_color;
			animation: pulse-border 7s infinite;
		}

		&::after{
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			border-radius: 50%;
			box-shadow: 0px 0px 0px 0px rgba(4,114,236,0.65);
			animation: pulse 7s infinite;
		}
	}

	.front{
		position: absolute;
		width: 100%;
		height: 100%;
		z-index: 5;
		backface-visibility: hidden;
		-webkit-backface-visibility: hidden;
		   -ms-backface-visibility: hidden;
		top: 50%;
		left: 50%;
		padding-top: 20px;
		text-align: center;
		border-radius: 50%;
		transform: translate(-50%, -50%) rotateY(0);
		transition: all .5s;
		animation: rotateInner 6s infinite;
		background: $gradient_color_from;
		@include linear-gradient(135deg, $gradient_color_from, $gradient_color_to);

		i{
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			font-size: 36px;
			color: #fff;
			animation: caller 6s infinite;
		}
	}

	.back{
		position: absolute;
		width: 100%;
		height: 100%;
		z-index: 15;
		backface-visibility: hidden;
		-webkit-backface-visibility: hidden;
		   -ms-backface-visibility: hidden;
		top: 50%;
		left: 50%;
		padding-top: 25px;
		border-radius: 50%;
		color: #fff;
		transform: translate(-50%, -50%) rotateY(180deg);
		transition: all .5s;
		animation: rotateInner-2 6s infinite;
		@include linear-gradient(135deg, $gradient_color_from, $gradient_color_to);

		p{
			margin-bottom: 0;
			font-size: 14px;
			line-height: 1.2em;
			text-align: center;
		}
	}

	@media all and (-ms-high-contrast:none){
		.front{
			z-index: 10;
			animation-name: rotateInner-ie-11;
		}

		.back{
			z-index: 5;
			animation: none !important;
			transform: translate(-50%, -50%) rotateY(0);
		}
	}

	@include breakpoint(extra-small){
		left: 20px;
		bottom: 20px;
		width: 65px;
		height: 65px;
		.order-call-card{
			width: 60px;
			height: 60px;
		}

		.front{
			padding-top: 15px;
			i{
				font-size: 30px;
			}
		}

		.back{
			padding-top: 15px;
		}
	}
}


@keyframes caller {
	0%{
		transform: translate(-50%, -50%) rotate(0);
	}

	4%{
		transform: translate(-50%, -50%) rotate(-7deg);
	}

	8%{
		transform: translate(-50%, -50%) rotate(7deg);
	}

	12%{
		transform: translate(-50%, -50%) rotate(-7deg);
	}

	16%{
		transform: translate(-50%, -50%) rotate(7deg);
	}

	20%{
		transform: translate(-50%, -50%) rotate(-7deg);
	}

	22%{
		transform: translate(-50%, -50%) rotate(0deg);
	}
}

@keyframes pulse {
	0%{
		box-shadow: 0px 0px 0px 0px rgba(4,114,236,0.40);
	}

	10%{
		box-shadow: 0px 0px 0px 10px rgba(4,114,236,0.65);
	}

	20%{
		box-shadow: 0px 0px 0px 0px rgba(4,114,236,0.40);
	}

	50%{
		box-shadow: 0px 0px 0px 0px rgba(4,114,236,0.40);
	}

	60%{
		box-shadow: 0px 0px 0px 10px rgba(4,114,236,0.65);
	}

	70%{
		box-shadow: 0px 0px 0px 0px rgba(4,114,236,0.40);
	}

	100%{
		box-shadow: 0px 0px 0px 0px rgba(4,114,236,0.40);
	}
}

@keyframes pulse-border {
	0%{
		top: 1%;
		left: 1%;
		width: 99%;
		height: 99%;
		opacity: 1;
	}

	10%{
		top: -25%;
		left: -25%;
		width: 150%;
		height: 150%;
		opacity: 0;
	}

	20%{
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		opacity: 0;
	}

	50%{
		top: 1%;
		left: 1%;
		width: 99%;
		height: 99%;
		opacity: 1;
	}

	60%{
		top: -25%;
		left: -25%;
		width: 150%;
		height: 150%;
		opacity: 0;
	}

	70%{
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		opacity: 0;
	}

	100%{
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		opacity: 1;
	}
}

@keyframes rotateInner{
	0%{
		transform: translate(-50%, -50%) rotateY(0) scale(1);
	}

	35%{
		transform: translate(-50%, -50%) rotateY(0) scale(1);
	}

	45%{
		transform: translate(-50%, -50%) rotateY(180deg) scale(0);
	}

	50%{
		transform: translate(-50%, -50%) rotateY(180deg) scale(0);
	}

	55%{
		transform: translate(-50%, -50%) rotateY(180deg) scale(0);
	}

	65%{
		transform: translate(-50%, -50%) rotateY(0) scale(1);
	}

	100%{
		transform: translate(-50%, -50%) rotateY(0) scale(1);
	}
}

@keyframes rotateInner-2{
	0%{
		transform: translate(-50%, -50%) rotateY(180deg) scale(0);
	}

	35%{
		transform: translate(-50%, -50%) rotateY(180deg) scale(0);
	}

	45%{
		transform: translate(-50%, -50%) rotateY(0) scale(1);
	}

	50%{
		transform: translate(-50%, -50%) rotateY(0) scale(1);
	}

	55%{
		transform: translate(-50%, -50%) rotateY(0) scale(1);
	}

	65%{
		transform: translate(-50%, -50%) rotateY(180deg) scale(0);
	}

	100%{
		transform: translate(-50%, -50%) rotateY(180deg) scale(0);
	}
}

@keyframes rotateInner-ie-11{
	0%{
		opacity: 1;
	}

	35%{
		opacity: 1;
	}

	45%{
		opacity: 0;
	}

	50%{
		opacity: 0;
	}

	55%{
		opacity: 0;
	}

	65%{
		opacity: 1;
	}

	100%{
		opacity: 1;
	}
}
/*===> END ORDER CALL <===*/