/*===> BEGIN HEADER <===*/
header{

	&.fixed-head{
		position: fixed;
		top: -100px;
		left: 0;
		z-index: 100;
		width: 100%;
		box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.45);
		transition: all .3s;
		transform: translateY(100px);
		.header-menu-wrap{
			.navigation ul li a{
				padding: 20px 0;
			}

			.header-menu .logo img{
				height: 50px;
				width: auto;
			}
		}
	}

	.header-contact-wrap{
		padding: 5px 0;
		background: $main_color;
		.inner-header-contact-wrap{
			display: flex;
			align-items: center;
			justify-content: space-between;
			.show_mobile{
				max-width: 40%;
				img{
					max-height: 75px;
				}
			}
		}

		.header-contact{
			display: flex;
			align-items: center;
			flex-wrap: wrap;
			a{
				display: flex;
				align-items: center;
				margin-right: 30px;
				font-weight: 400;
				color: #fff;
				i{
					margin-right: 5px;
				}
			}
		}
	}

	.header-menu{
		display: flex;
		align-items: center;
		justify-content: space-between;
		.type_third{
			border: 1px solid  $main_color;
			color: $main_color;
		}

		a{
			/* transition: all .3s; */
		}

		.logo{
			img{
				max-width: 180px;
				transition: all .2s;
			}

			svg{
				width: 130px !important;
				height: auto;
				image{
					height: auto;
					width: 130px !important;
				}
			}
		}
	}

	.header-menu-wrap{
		background: #fff;
		&.fixed-head{
			position: fixed;
			left: 0;
			z-index: 500;
			width: 100%;
		}
	}

	@include breakpoint(medium){
		.header-menu{
			.logo{
				img{
					height: 70px;
				}
			}
		}
	}

	@include breakpoint(small){
		.header-menu{
			padding: 10px 0;
		}
	}

	@include breakpoint(extra-small){
		&.fixed-head{
			.header-contact-wrap{
				display: none;
			}
		}

		.header-contact-wrap{
			.header-contact{
				flex-direction: column;
				align-items: flex-start;
				a{
					margin-right: 0;
					margin-bottom: 5px;
					font-size: 18px;
					&:last-child{
						margin-bottom: 0;
					}
				}
			}
		}
	}

	@include breakpoint(mobile){
		.header-menu{
			.type_third{
				font-size: 16px;
			}
		}
	}
}
/*===> END HEADER <===*/