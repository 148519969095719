/*===> Begin seo text <===*/
.seo-text-wrap {
  position: relative;
  margin-bottom: 50px;
  height: 96px;
  overflow: hidden;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 2;
    width: 100%;
    height: 35px;
    background: -moz-linear-gradient(top, rgba(255,255,255,0) 0%, rgba(255,255,255,0.5) 21%, rgba(255,255,255,0.49) 50%, rgba(255,255,255,1) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top, rgba(255,255,255,0) 0%,rgba(255,255,255,0.5) 21%,rgba(255,255,255,0.49) 50%,rgba(255,255,255,1) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, rgba(255,255,255,0) 0%,rgba(255,255,255,0.5) 21%,rgba(255,255,255,0.49) 50%,rgba(255,255,255,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  }

  &.is-show {
    height: 100%;
    &::after {
      display: none;
    }
  }

  p {
    color: #999;
  }


  .seo-visible {
    position: relative;


  }

  h4, .h4 {
    margin-bottom: 20px;
  }

  .seo-list {
    margin-bottom: 20px;
    li {
      display: flex;

      &::before {
        width: 18px;
        height: 18px;
        min-width: 18px;
        -webkit-background-size: 1500%;
        background-size: 2000%;
        background-position: 79px -14px;
      }
    }
  }

  .hide-seo-text {
    display: none;
  }

  .show-seo {
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: 10;
    margin-bottom: 0;
    padding: 0 10px;
    background: #ffffff;
    font-weight: 400;
    -webkit-transition: all .2s;
    -moz-transition: all .2s;
    -ms-transition: all .2s;
    -o-transition: all .2s;
    transition: all .2s;
    cursor: pointer;
    text-align: right;
    &:hover {
      color: $second_color;
    }
  }
}
/*===> End seo text <===*/
