/*===> BEGIN ADVANTAGES <===*/
.advantages-wrap{
	display: flex;
	flex-wrap: wrap;
	.advantages{
		padding: 100px 0 50px;
		h2, .h2{
			text-align: center;
			text-transform: uppercase;
		}

		.advantage-card{
			margin-bottom: 50px;
			.img{
				display: block;
				margin-left: auto;
				margin-right: auto;
				margin-bottom: 30px;
				width: 92px;
				height: 92px;

				&.first {
					background-position: -4px -2px;
				}

				&.second {
					background-position: -100px -2px;
				}

				&.third {
					background-position: -196px -2px;
				}

				&.fourth {
					background-position: -292px -2px;
				}
			}

			svg{
				display: block;
				width: 92px;
				height: 92px;
				margin-left: auto;
				margin-right: auto;
				margin-bottom: 30px;
			}

			h6, .h6{
				margin-bottom: 0;
				font-weight: 400;
				text-align: center;
			}
		}
	}

	@include breakpoint(small){
		.advantages{
			h2, .h2{
				text-align: center;
			}
		}
	}

	@include breakpoint(extra-small){
		.advantages{
			padding: 50px 0 0;
			.advantage-card{
				display: flex;
				align-items: center;
				margin-bottom: 30px;
				.img{
					width: 60px;
					min-width: 60px;
					height: 60px;
					margin: 0;
					margin-right: 10px;
					background-size: 660%;

					&.first {
						background-position: -1px -1px;
					}

					&.second {
						background-position: -64px -1px;
					}

					&.third {
						background-position: -126px -1px;
					}

					&.fourth {
						background-position: -188px -1px;
					}
				}

				h6, .h6{
					text-align: left;
				}
			}
		}
	}
}

/*===> END ADVANTAGES <===*/