/*===> BEGIN STRATEGY <===*/
.strategy-wrap{
	overflow-x: hidden;
	padding-top: 100px;
	padding-bottom: 100px;
	background: #f5f5f5;

	h2, .h2{
		max-width: 640px;
		margin-left: auto;
		margin-right: auto;
		margin-bottom: 60px;
		text-align: center;
		text-transform: uppercase;
	}

	.button{
		display: block;
		max-width: 360px;
		margin: 0 auto;
	}

	@include breakpoint(extra-small){
		padding-top: 50px;
		padding-bottom: 50px;
		h2, .h2{
			margin-bottom: 50px;
		}
		
		.button{
			padding: 15px 20px;
		}
	}
}
/*===> END STRATEGY <===*/