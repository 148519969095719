/*===> BEGIN POPUP BG <===*/
.popup-bg{
	position: fixed;
	top: 0;
	left: 0;
	z-index: 800;
	width: 100%;
	height: 100%;
	background: rgba(0,0,0, .45);
	transition: all .25s;
	opacity: 0;
	visibility: hidden;
	&.active-popup{
		visibility: visible;
		opacity: 1;
	}
}
/*===> END POPUP BG <===*/