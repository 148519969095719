body {
    font-family: $main_font;
    font-weight: 400;
    font-size: 16px;
    line-height: 1.5em;
    color: #000;

    ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: #6b656e;
        opacity: 1; /* Firefox */
    }

    :-ms-input-placeholder { /* Internet Explorer 10-11 */
        color: #6b656e;
    }

    ::-ms-input-placeholder { /* Microsoft Edge */
        color: #6b656e;
    }

    h1, .h1, 
    h2, .h2, 
    h3, .h3, 
    h4, .h4, 
    h5, .h5, 
    h6, .h6 {
        margin-top: 0;
        font-family: $header_font;
        line-height: 1.3em;
    }

    h1, .h1 {
        margin-bottom: 60px;
        font-weight: 700;
        font-size: 54px;
        @include breakpoint(small){
            margin-bottom: 50px;
            font-size: 46px;
        }

        @include breakpoint(extra-small) {
            margin-bottom: 40px;
            font-size: 40px;
        }

        @include breakpoint(mobile){
            margin-bottom: 30px;
            font-size: 28px;
        }
    }

    h2, .h2 {
        margin-bottom: 50px;
        font-weight: 500;
        font-size: 40px;
        @include breakpoint(small){
            margin-bottom: 45px;
            font-size: 36px;
        }

        @include breakpoint(extra-small){
            margin-bottom: 35px;
            font-size: 30px;
        }

        @include breakpoint(mobile){
            margin-bottom: 25px;
            font-size: 24px;
        }
    }

    h3, .h3 {
        margin-bottom: 40px;
        font-weight: 500;
        font-size: 30px;
        @include breakpoint(extra-small){
            margin-bottom: 35px;
            font-size: 26px;
        }

        @include breakpoint(mobile){
            margin-bottom: 25px;
            font-size: 20px;
        }
    }

    h4, .h4 {
        margin-bottom: 40px;
        font-weight: 500;
        font-size: 28px;
        @include breakpoint(extra-small){
            margin-bottom: 35px;
            font-size: 24px;
        }

        @include breakpoint(mobile){
            margin-bottom: 25px;
            font-size: 20px;
        }
    }

    h5, .h5 {
        margin-bottom: 30px;
        font-weight: 500;
        font-size: 24px;
        @include breakpoint(extra-small){
            margin-bottom: 25px;
            font-size: 22px;
        }

        @include breakpoint(mobile){
            margin-bottom: 20px;
            font-size: 18px;
        }
    }

    h6, .h6 {
        margin-bottom: 30px;
        font-weight: 700;
        font-size: 20px;
        @include breakpoint(extra-small){
            margin-bottom: 18px;
        }

        @include breakpoint(mobile){
            margin-bottom: 20px;
            font-size: 16px;
        }
    }

    p{
        margin-bottom: 15px;
        font-family: $main_font;
        font-weight: 400;
        font-size: 16px;
        line-height: 1.5em;
    }

    img {
        max-width: 100%;
        display: inline-block;
    }

    a{
        display: inline-block;
        text-decoration: none;
        font-family: $header_font;
        font-weight: 700;
        font-size: 20px;
        color: #000;
        outline: none !important;

        &::-moz-focus-inner {
            border: 0 !important;
        }

        &:hover{
            text-decoration: none;
            color: #000;
        }
    }

    ul{
        padding: 0;
        margin-bottom: 30px;
        list-style-position: inside;
        list-style: none;
        li{
            margin-bottom: 20px;
            position: relative;
            &:last-child{
                margin-bottom: 0;
            }

            &::before{
                content: '';
                width: 24px;
                min-width: 24px;
                height: 24px;
                margin-right: 20px;
                background-image: url(../img/eckit.svg);
                -webkit-background-size: auto;
                background-size: auto;
                background-position: -1098px -31px;
            }

            p{
                display: inline-block;
                margin-bottom: 0;
            }

            ul{
                margin: 20px 0;
                padding-left: 25px;
                li{
                    &:last-child{
                        margin-bottom: 5px;
                    }
                }
            }
        }
        

        @include breakpoint(mobile){
            li{
                &::before{
                    margin-right: 15px;
                }

                ul{
                    padding-left: 15px;
                }
            }
        }
    }

    input{
        border: none; 
        padding: 15px 20px;
        outline: none;
        border-radius: 10px;
        background: #fff;
        font-family: $main_font;
        font-weight: 400;
        font-size: 16px;
        line-height: 16px;
    }

    .show_mobile{
        display: none;
        @include breakpoint(small){
            display: block;
        }
    }

    .hide_mobile{
        @include breakpoint(small){
            display: none;
        }
    }

    .img {
        position: relative;
        background-image: url(../img/eckit.svg);
        img {
            position: absolute;
            top: 0;
            left: 0;
            width: auto;
            max-width: none;
        }
    }
}