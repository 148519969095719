/*===> BEGIN HUMBURGER <===*/
.humburger{
	display: none;
	@include breakpoint(small){
		position: relative;
		width: 25px;
		height: 20px;
		display: flex;
		flex-direction: column;
		justify-content: space-between;

		span{
			display: block;
			height: 4px;
			border-radius: 2px;
			background: #000;
		}
	}
}
/*===> END HUMBURGER <===*/