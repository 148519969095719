/*===> BEGIN BONUS <===*/
.bonuses-wrap{
	padding: 100px 0 0px;
	h2, .h2{
		margin-bottom: 70px;
		text-align: center;
		text-transform: uppercase;
	}

	.bonuses {
		display: flex;
		flex-wrap: wrap;
		margin-bottom: 0;
		padding: 0;
		counter-reset: myCounter;
		> li {
			position: relative;
			display: block;
			width: 50%;
			margin-bottom: 50px;
			list-style: decimal-leading-zero;
			&:nth-child(even) {
				padding-left: 15px;
			}

			&:nth-child(odd) {
				padding-right: 15px;
			}

			&:nth-child(1) {
				ul {
					background: url(../img/bonus-bg-1.png) no-repeat center;
				}
			}
			&:nth-child(2) {
				ul {
					background: url(../img/bonus-bg-2.png) no-repeat center;
				}
			}
			&:nth-child(3) {
				ul {
					background: url(../img/bonus-bg-3.png) no-repeat center;
				}
			}
			&:nth-child(4) {
				ul {
					background: url(../img/bonus-bg-4.png) no-repeat center;
				}
			}

			.bonus-head {
				display: flex;
				align-items: center;
				margin-bottom: 40px;
				h5, .h5 {
					margin-bottom: 0;
					text-transform: uppercase;
				}

				.bonus-count {
					position: relative;
					width: 100px;
					min-width: 100px;
					height: 100px;
					margin-right: 15px;
					border-radius: 15px;
					@include linear-gradient(135deg, $gradient_color_from, $gradient_color_to);
					&:before {
						content: counter(myCounter, decimal-leading-zero);
						counter-increment: myCounter;
						position: absolute;
						left: 50%;
						top: 50%;
						font-family: $header_font;
						font-weight: 500;
						font-size: 40px;
						color: #fff;
						transform: translate(-50%, -50%);
					}
				}
			}

			ul {
				width: 100%;
				max-width: 380px;
				margin-bottom: 0;
				li {
					display: flex;
				}
			}
		}
	}

	@include breakpoint(small){
		.bonuses{
			>li{
				width: 100%;
				max-width: 600px;
				margin-left: auto;
				margin-right: auto;

				&:nth-child(even){
					padding-left: 0;
				}

				&:nth-child(odd){
					padding-right: 0;
				}
			}
		}
	}

	@include breakpoint(extra-small){
		padding: 50px 0;
		h2, .h2{
			margin-bottom: 50px;
		}

		.bonuses{
			margin-left: -15px;
			margin-right: -15px;
			>li{
				margin-bottom: 0;
				.bonus-head{
					margin-bottom: 0;
					padding-right: 15px;
					padding-top: 15px;
					padding-bottom: 15px;
					background: #f5f5f5;
					border: 1px solid  #e8e8e8;
					transition: background .3s;
					&.active-accordeon{
						border: none;
						@include linear-gradient(135deg, $gradient_color_from, $gradient_color_to);
						h5, .h5{
							color: #fff;
						}

						.bonus-count{
							&:before{
								font-size: 36px;
								color: #fff;
							}
						}
					}
					h5, .h5{
						margin-bottom: 0;
						font-weight: 500;
						text-transform: uppercase;
					}

					.bonus-count{
						width: 90px;
						min-width: 90px;
						height: 90px;
						margin-right: 0;
						border-radius: 0;
						background: transparent;
						&:before{
							font-size: 36px;
							color: #000;
						}
					}
				}

				ul{
					padding: 20px 15px 0;
					margin-bottom: 20px;
				}
			}
		}
	}

	@include breakpoint(mobile){
		h2, .h2{
			margin-bottom: 30px;
		}

		.bonuses{
			>li{
				.bonus-head{
					.bonus-count{
						width: 60px;
						min-width: 60px;
						height: 100%;
						margin-right: 0;
						border-radius: 0;
						background: transparent;
						&:before{
							font-size: 36px;
						}
					}
				}
			}
		}
	}
}
/*===> END BONUS <===*/