/*===> BEGIN FOOTER <===*/
footer{
	padding-top: 175px;
	padding-bottom: 70px;
	background: $main_color;

	.footer-logo{
		height: 180px;
		width: auto;
	}

	.footer-contact{
		margin-bottom: 0;
		padding: 0;
		li{
			margin-bottom: 20px;
			&:last-child{
				margin-bottom: 0;
			}

			&:before{
				display: none;
			}

			p{
				display: flex;
				align-items: flex-start;
				font-weight: 400;
				color: #fff;
				span{
					display: flex;
					align-items: center;
					justify-content: center;
					width: 30px;
					min-width: 30px;
					height: 30px;
					border-radius: 50%;
					background: #fff;
					margin-right: 13px;
					font-size: 18px;
					color: $second_color;
				}

				a{
					font-family: $main_font;
					font-weight: 400;
					font-size: 18px;
					color: #fff;
					&:hover{
						text-decoration: underline;
					}
				}
			}
		}
	}

	.footer-nav{
		margin-bottom: 0;
		padding: 0;
		li{
			margin-bottom: 10px;
			&:last-child{
				margin-bottom: 0;
			}

			&:before{
				display: none;
			}

			a{
				font-weight: 400;
				font-size: 18px;
				color: #fff;
				&:hover{
					text-decoration: underline;
				}
			}
		}
	}

	@include breakpoint(medium){
		.footer-logo{
			height: auto;
		}
	}

	@include breakpoint(small){
		.footer-logo{
			width: 100%;
			max-width: 350px;
		}

		img{
			display: block;
			margin-left: auto;
			margin-right: auto;
			margin-bottom: 50px;
		}
	}

	@include breakpoint(extra-small){
		padding-top: 40px;
		padding-bottom: 40px;
		.footer-contact{
			max-width: 350px;
			margin-left: auto;
			margin-right: auto;
			li{
				p{
					flex-direction: column;
					align-items: center;
					text-align: center;
					span{
						margin-right: 0;
						margin-bottom: 10px;
					}
				}
			}
		}

		.footer-nav{
			display: none;
		}
	}
}
/*===> END FOOTER <===*/