@media all and (-ms-high-contrast:none){
    /* IE10 */

    /* IE11 */
    *::-ms-backdrop, .foo { color: red } 

    .order-call{
    	.front i{
    		position: static;
    	}
    }

    .strategy-list .text{
    	perspective: none;
    	&:hover{
     		.front{
                z-index: 0;
     			opacity: 0;
     			transform: rotateY(0);
     		}

     		.back{
                z-index: 20;
     			opacity: 1;
     			transform: rotateY(0);
     		}
    	}
    	.front{
     		z-index: 20;
     		transform: rotateY(0);
    	}

    	.back{
     		z-index: 10;
     		transform: rotateY(0);
     		opacity: 0;
    	}
    }

    .strategy-list .count-line{
    	transform: translateY(-50%);
    }

    .client-wrap .slick-arrow{
        bottom: 75px;
    }
}

/*edge*/
@supports (-ms-ime-align:auto) {
	.strategy-list .text{
		perspective: none;
		&:hover{
			.front{
                z-index: 0;
				opacity: 0;
				transform: rotateY(0);
			}

			.back{
                z-index: 20;
				opacity: 1;
				transform: rotateY(0);
			}
		}
		.front{
			z-index: 20;
			transform: rotateY(0);
		}

		.back{
			z-index: 10;
			transform: rotateY(0);
			opacity: 0;
		}
	}
}