/*===> BEGIN BACK TOP <===*/
p.back-top{
	position: fixed;
	z-index: 99;
	opacity: .75;
	right: 0;
	bottom: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 50px;
	height: 50px;
	margin-right: 30px;
	margin-bottom: 60px;
	border-radius: 8px;
	box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.62);
	background: $main_color;
	cursor: pointer;
	transition: all .3s;
	transform: scale(1) translateY(300px);
	@include linear-gradient(135deg, $gradient_color_from, $gradient_color_to);

	&.show_btn{
		transform: scale(1) translateY(0px);
	}

	&:hover{
		opacity: 1;
		box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.62);
	}

	&:active{
		box-shadow: inset 0px 2px 5px 0px rgba(0,0,0,0.62);
	}

	i{
		font-size: 28px;
		color: #fff;
	}

	@include breakpoint(extra-small){
		margin-right: 25px;
		margin-bottom: 100px;
	}

	@include breakpoint(mobile){
		margin-right: 25px;
		margin-bottom: 100px;
	}
}
/*===> END BACK TOP <===*/