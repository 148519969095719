/*===> BEGIN STRATEGY LIST <===*/
.strategy-list{
	position: relative;
	width: 100%;
	max-width: 1660px;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 70px;
	padding-left: 0;
	.strategy-list-top{
		position: relative;
		display: flex;
		justify-content: space-around;
		margin-left: auto;
		margin-right: auto;
		height: 400px;
		.strategy-card{
			position: absolute;
			top: 0;

			&:nth-child(1){
				left: 13.7%;
				.text,
				.count,
				.circle{
					transition-delay: .4s;
				}
			}
			&:nth-child(2){
				position: relative;
				left: auto;	
				.text,
				.count,
				.circle{
					transition-delay: .8s;
				}
			}
			&:nth-child(3){
				left: auto;
				right: 13.7%;
				.text,
				.count,
				.circle{
					transition-delay: 1.2s;
				}
			}

			.text{
				transform: translateY(-100px);
			}
		}
	}

	.strategy-list-bottom{
		display: flex;
		justify-content: space-between;
		margin-top: -124px;
		.strategy-card{
			flex-direction: column-reverse;
			&:nth-child(1){
				.text,
				.count,
				.circle{
					transition-delay: .2s;
				}
			}
			&:nth-child(2){
				.text,
				.count,
				.circle{
					transition-delay: .6s;
				}
			}
			&:nth-child(3){
				.text,
				.count,
				.circle{
					transition-delay: 1s;
				}
			}
			&:nth-child(4){
				.text,
				.count,
				.circle{
					transition-delay: 1.4s;
				}
			}

			.circle{
			}
		}
	}

	.strategy-card{
		position: relative;
		z-index: 10;
		display: flex;
		flex-direction: column;
		align-items: center;
		overflow: visible;
		width: 300px;
		height: 400px;
		transition: all .5s;

		&:hover{
			z-index: 10;
		}

		&.show-card{
			opacity: 1;
			transform: translateY(0);
			&:nth-child(even){
				.text{
					transform: translateY(0);
				}
			}

			.text{
				opacity: 1;
				transform: translateY(0);
			}

			.count{
				opacity: 1;
			}

			.circle{
				transform: scale(1);
			}
		}

		.circle{
			position: static;
			top: 100%;
			left: 50%;
			z-index: 100;
			width: 45px;
			height: 45px;
			margin: 15px 0;
			border-radius: 50%;
			border: 12px solid  #fff;
			background: $second_color;
			box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.05);
			transition: all .3s;
			transform: scale(0);
		}
	}

	.text{
		position: relative;
		z-index: 2;
		display: flex;
		flex-direction: column;
		align-items: center;
		width: 300px;
		height: 300px;
		border-radius: 10px;
		opacity: 0;
		perspective: 1000px;
		transition: all .5s;
		-webkit-transition: all .5s;
		transform: translateY(100px);
		

		&:hover{
			.front{
				transform: rotateY(-180deg);
				-webkit-transform: rotateY(-180deg);
			}

			.back{
				transform: rotateY(0);
				-webkit-transform: rotateY(0);
			}
		}

		.front,
		.back{
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			border-radius: 10px;
			box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.05);
			backface-visibility: hidden;
			transition: all .5s;
			-webkit-transition: all .5s;
			transition-timing-function: ease-out;
			-webkit-transition-timing-function: ease-out;
		}

		.front{
			z-index: 10;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			padding: 15px 25px;
			background: #fff;
			h5, .h5{
				width: 100%;
				margin-bottom: 0;
				font-weight: 400;
				text-align: center;
			}

			.img{
				margin-bottom: 30px;
			}
		}

		.back{
			z-index: 1;
			padding: 30px 20px;
			transform: rotateY(180deg);
			@include linear-gradient(135deg, $gradient_color_from, $gradient_color_to);

			p{
				color: #fff;
			}

			ul{
				li{
					display: flex;
					flex-direction: row;
					align-items: flex-start;
					margin-bottom: 10px;
					width: 100%;
					&:before{
						content: '';
						min-width: 7px;
						width: 7px;
						min-height: 7px;
						height: 7px;
						margin-right: 10px;
						background: #fff;
						border-radius: 50%;
						transform: translateY(7px);
					}

					p{
						color: #fff;
					}
				}
			}
		}
	}

	.count{
		font-family: $header_font;
		font-weight: 400;
		font-size: 30px;
		transition: all .2s;
		opacity: 0;
	}

	.count-line{
		position: absolute;
		top: 50%;
		left: 9%;
		width: 0;
		width: 0%;
		height: 15px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		list-style: none;
		transform: translateY(-50%);
		transition: all 1.8s;

		&.height-line,
		&.width-line{
			justify-content: space-between;
			width: 82%;
			background: #fff;
			
			&::after{
				position: absolute;
				left: 0;
				width: 100%;
			}
		}
	}

	@include breakpoint(leptop){
		.strategy-list-top{
			height: 350px;
		}

		.strategy-card{
			width: 250px;
			height: 350px;
		}

		.text{
			width: 250px;
			height: 250px;
			.front{
				padding: 15px;
			}

			.back{
				padding: 20px;
				overflow-y: auto;
			}
		}
	}

	@include breakpoint(medium){
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		height: auto;

		.strategy-list-top{
			padding-top: 100px;
			flex-direction: column;
			align-items: center;
			width: 50%;
			max-width: 415px;
			height: auto;
			margin-right: -150px;
			margin-left: 0;

			.strategy-card{
				position: relative;
				left: auto !important;
				right: auto !important;
			}
		}

		.strategy-list-bottom{
			flex-direction: column;
			width: 50%;
			max-width: 415px;
			margin-top: 0;
			.strategy-card{
				flex-direction: row-reverse;
				&:last-child{
					margin-bottom: 0;
				}
			}
		}

		.strategy-card{
			flex-direction: row;
			width: 100%;
			height: 100%;
			margin-bottom: 100px;

			.circle{
				margin: 0 20px;
			}
		}

		.text{
			height: 300px;
			width: 300px;
		}

		.count-line{
			top: 130px;
			left: 50%;
			width: 15px;
			height: 80%;
			flex-direction: column;
			align-items: center;
			justify-content: space-between;
			transform: translateX(-50%);

			&::after{
				width: 100%;
				height: 0;
			}

			&.width-line{
				top: 130px;
				left: 50%;
				width: 15px;
				height: 81%;
				flex-direction: column;
				align-items: center;
				justify-content: space-between;
				transform: translateX(-50%);
			}

			&.height-line{
				background: #fff;
				width: 15px;

				.circle{
					margin-right: 0;
					transform: scale(1);
					transition-delay: .2s;
					&:nth-child(1){
						transition-delay: .2s;
					}
					&:nth-child(2){
						transition-delay: .4s;
					}
					&:nth-child(3){
						transition-delay: .6s;
					}
					&:nth-child(4){
						transition-delay: .8s;
					}
					&:nth-child(5){
						transition-delay: 1s;
					}
					&:nth-child(6){
						transition-delay: 1.2s;
					}
					&:nth-child(7){
						transition-delay: 1.4s;
					}
				}
			}
		}
	}

	@include breakpoint(extra-small){
		display: none;
	}
}
/*===> END STRATEGY LIST <===*/