.img {
	position: relative;
	background-image: url(../img/eckit.svg);
	-webkit-background-size: auto;
	background-size: auto;
	img {
		position: absolute;
		top: 0;
		left: 0;
		width: auto;
		max-width: none;
	}

	&.phone {
		width: 15px;
		height: 21px;
		display: inline-block;
		background-position: 1964px 187px;
	}

	&.envelope {
		width: 20px;
		height: 18px;
		display: inline-block;
		background-position: 1966px -4px;
	}

	&.rocket {
		display: block;
		width: 20px;
		min-width: 20px;
		height: 21px;
		background-position: 188px -34px;
	}

	&.left-white {
		display: block;
		width: 18px;
		min-width: 18px;
		height: 21px;
		background-position: 83px 0px;
	}

	&.right-white {
		display: block;
		width: 18px;
		min-width: 18px;
		height: 21px;
		background-position: 83px -19px;
	}

	&.satrategy-kompleks {
		width: 80px;
		min-width: 80px;
		height: 80px;
		background-position: 355px -97px;

		&.mob {
			width: 55px;
			min-width: 55px;
			height: 55px;
			background-position: 233px -62px;
			-webkit-background-size: 730%;
			background-size: 730%;
		}
	}

	&.satrategy-techno {
		width: 80px;
		min-width: 80px;
		height: 80px;
		background-position: -4px -97px;

		&.mob {
			width: 55px;
			min-width: 55px;
			height: 55px;
			background-position: -1px -62px;
			-webkit-background-size: 730%;
			background-size: 730%;
		}
	}

	&.satrategy-outside {
		width: 80px;
		min-width: 80px;
		height: 80px;
		background-position: 269px -97px;

		&.mob {
			width: 55px;
			min-width: 55px;
			height: 55px;
			background-position: 177px -62px;
			-webkit-background-size: 730%;
			background-size: 730%;
		}
	}

	&.satrategy-compete {
		width: 80px;
		min-width: 80px;
		height: 80px;
		background-position: 99px -97px;

		&.mob {
			width: 55px;
			min-width: 55px;
			height: 55px;
			background-position: 66px -62px;
			-webkit-background-size: 730%;
			background-size: 730%;
		}
	}

	&.satrategy-structura {
		width: 80px;
		min-width: 80px;
		height: 80px;
		background-position: -89px -97px;

		&.mob {
			width: 55px;
			min-width: 55px;
			height: 55px;
			background-position: -57px -62px;
			-webkit-background-size: 730%;
			background-size: 730%;
		}
	}

	&.satrategy-kontent {
	  width: 80px;
	  min-width: 80px;
	  height: 80px;
	  background-position: -174px -97px;

      &.mob {
        width: 55px;
        min-width: 55px;
        height: 55px;
        background-position: -113px -62px;
        -webkit-background-size: 730%;
        background-size: 730%;
      }

	}

	&.satrategy-plan {
		width: 80px;
		min-width: 80px;
		height: 80px;
		background-position: 183px -97px;

      &.mob {
        width: 55px;
        min-width: 55px;
        height: 55px;
        background-position: -113px -62px;
        -webkit-background-size: 730%;
        background-size: 730%;
      }
	}

    &.black-left {
      display: block;
      width: 18px;
      min-width: 18px;
      height: 10px;
      background-position: 38px -84px;
    }

    &.black-right {
      display: block;
      width: 18px;
      min-width: 18px;
      height: 10px;
      background-position: 38px -74px;
    }

	&.result-1 {
		width: 41px;
		min-width: 41px;
		height: 52px;
		background-position: 46px 56px;
		@include breakpoint(mobile) {
			-webkit-background-size: 1300%;
			background-size: 1300%;
			background-position: 42px 52px;
		}
	}

	&.result-2 {
		width: 65px;
		min-width: 65px;
		height: 50px;
		background-position: 171px 56px;
		@include breakpoint(mobile) {
			width: 54px;
			min-width: 54px;
			height: 40px;
			-webkit-background-size: 900%;
			background-size: 900%;
			background-position: 136px 43px;
		}
	}

	&.result-3 {
		width: 54px;
		min-width: 54px;
		height: 51px;
		background-position: 228px 54px;
		@include breakpoint(mobile) {
			width: 50px;
			min-width: 50px;
			height: 51px;
			-webkit-background-size: 1000%;
			background-size: 1000%;
			background-position: 190px 52px;
		}
	}

	&.result-4 {
		width: 41px;
		min-width: 41px;
		height: 51px;
		margin-right: -10px;
		background-position: 276px 54px;
		@include breakpoint(mobile) {
			-webkit-background-size: 1300%;
			background-size: 1300%;
			background-position: 244px 53px;
		}
	}

	&.result-5 {
		width: 50px;
		min-width: 50px;
		height: 51px;
		background-position: 100px 56px;
		@include breakpoint(mobile) {
			width: 48px;
			min-width: 48px;
			height: 49px;
			-webkit-background-size: 1200%;
			background-size: 1200%;
			background-position: 97px 53px;
		}
	}

	&.tarif-1 {
		width: 60px;
		min-width: 60px;
		height: 60px;
		background-position: 344px 62px;
	}

	&.tarif-2 {
		width: 60px;
		min-width: 60px;
		height: 60px;
		background-position: 477px 62px;
	}

	&.tarif-3 {
		width: 60px;
		min-width: 60px;
		height: 60px;
		background-position: 411px 64px;
	}

	&.tarif-4 {
		width: 60px;
		min-width: 60px;
		height: 60px;
		background-position: -4px 64px;
	}

	&.tarif-5 {
		width: 60px;
		min-width: 60px;
		height: 60px;
		background-position: -72px 64px;
	}

	&.tags {
		display: inline-block;
		width: 25px;
		min-width: 25px;
		height: 21px;
		background-position: 220px -4px;
	}

	&.map-signs {
		display: inline-block;
		width: 20px;
		min-width: 20px;
		height: 21px;
		background-position: 220px -35px;
	}

	&.calendar {
		display: inline-block;
		width: 18px;
		min-width: 18px;
		height: 21px;
		background-position: 220px -66px;
	}

	&.map-marker {
		display: inline-block;
		width: 14px;
		min-width: 14px;
		height: 18px;
		background-position: 152px -6px;
	}

	&.phone-volume {
		display: inline-block;
		width: 14px;
		min-width: 14px;
		height: 18px;
		background-position: 152px -36px;
	}

	&.envelope-blue {
		display: inline-block;
		width: 18px;
		min-width: 18px;
		height: 18px;
		background-position: 155px -62px;
	}

	&.chevron-up {
		display: block;
		width: 26px;
		min-width: 26px;
		height: 25px;
		background-position: 49px 0;
	}

	&.phone-big {
		display: block;
		width: 38px;
		min-width: 38px;
		height: 38px;
		background-position: 99px -44px;
	}

	&.close-popup-img {
		display: block;
		width: 14px;
		min-width: 14px;
		height: 21px;
		background-position: 40px -30px;
	}
}