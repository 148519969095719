/*===> BEGIN MAIN SLIDER <===*/
.main-slider-wrap{
	position: relative;
	overflow: hidden;
	background: url(../img/main-slider-bg.jpg) no-repeat center;
	background-size: cover;
	.main-slider-control{
		position: absolute;
		bottom: 0;
		right: 0;
		display: flex;
		align-items: center;
		padding: 20px 15px;
		color: #fff;
		i{
			font-size: 20px;
		}

		.img {
			width: 18px;
			height: 21px;
		}

		.prev{
			margin-right: 15px;
			cursor: pointer;
		}

		.next{
			margin-left: 15px;
			cursor: pointer;
		}

		.count-slider{
			display: flex;
			align-items: center;
			font-family: $header_font;
			font-weight: 400;
			font-size: 30px;
			span{
				&:first-child{
					font-size: 40px;
				}
			}
		}
	}

	.inner-main-slider{
		position: relative;
		display: flex;
		height: 770px;
		min-height: 750px;
		max-height: 900px;
		.slide-desc{
			position: relative;
			z-index: 10;
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			justify-content: center;
			width: 56%;
			height: 100%;
			h1, .h1{
				color: #fff;
			}
		}

		.main-slider{
			position: absolute;
			bottom: 0;
			right: 0;
			width: 590px;
		}
	}

	.main-slider{
		height: 100%;
		div{
			height: inherit;
		}
		.slide{
			height: inherit;
			overflow: hidden;
			.inner-slide{
				display: flex;
				align-items: center;
				height: inherit;	
			}

			.slide-privilege{
				position: relative;
				width: 100%;
				height: 100%;
				display: flex;
				flex-direction: column;
				justify-content: flex-end;
				img{
					position: absolute;
					bottom: 0;
					z-index: 2;
					height: 700px;
				}

				.privilege{
					position: absolute;
					top: 50px;
					right: 0;
					display: flex;
					flex-direction: column;
					align-items: center;
					justify-content: center;
					width: 320px;
					height: 320px;
					padding: 10px 20px;
					border-radius: 50%;
					background: $main_color;
					text-align: center;
					text-transform: uppercase;
					color: #fff;
					h1, .h1{
						margin-bottom: 10px;
					}

					h5, .h5{
						width: 100%;
						margin: 0;
						line-height: 1.3em;
					}
				}
			}
		}
	}

	@include breakpoint(medium){
		.main-slider{
			height: 780px;
		}

		.inner-main-slider{
			.slide-desc{
				position: relative;
				z-index: 10;
				display: flex;
				flex-direction: column;
				align-items: flex-start;
				justify-content: center;
				width: 65%;
				height: 100%;
				h1, .h1{
					color: #fff;
				}
			}

			.main-slider{
				position: absolute;
				bottom: 0;
				right: 0;
				width: 590px;
			}
		}
	}

	@include breakpoint(small){
		

		.inner-main-slider{
			height: 500px;
			min-height: 500px;
			.slide-desc{
				align-items: center;
				width: 100%;
				text-align: center;
			}
			.main-slider{
				display: none;
			}
		}

		.main-slider-control{
			display: none;
		}
	}

	@include breakpoint(extra-small){
		.main-slider{
			height: 400px;
			min-height: 400px;
		}
	}

	@include breakpoint(mobile){
		.main-slider{
			height: 350px;
			min-height: 350px;
		}
	}
}
/*===> END MAIN SLIDER <===*/