/*===> BEGIN SALE <===*/
.sale-wrap{
	position: relative;
	z-index: 2;
	width: 100%;
	max-width: 1390px;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: -120px;
	padding: 20px 0 40px;
	background: #f5f5f5;
	box-shadow: 0px 6px 29px 0px rgba(0, 0, 0, 0.07);

	.sale-desc{
		display: flex;
		align-items: center;
		margin-bottom: 20px;
		.percent-sale{
			display: flex;
			flex-direction: column;
			margin-right: 20px;
			span{
				margin-bottom: -17px;
				font-family: $header_font;
				font-weight: 400;
				font-size: 130px;
				line-height: 130px;
			}

			p{
				font-family: $header_font;
				font-weight: 700;
				font-size: 20px;
				padding: 5px 0;
				background: $third_color;
				border-radius: 10px;
				text-align: center;
				color: #fff;
			}
		}
	}

	.text-sale{
		h4, .h4{
			margin-bottom: 20px;
			text-transform: uppercase;
		}

		p{
			margin-bottom: 0;
			width: 100%;
			max-width: 700px;
			font-family: $header_font;
			font-weight: 400;
			font-size: 18px;
			span{
				font-weight: 700;
				&:last-child{
					font-size: 24px;
				}
			}
		}
	}

	@include breakpoint(extra-small){
		margin-bottom: 0;
		.sale-desc{
			flex-direction: column;
			align-items: center;
			text-align: center;
			.percent-sale{
				margin-bottom: 20px;
				margin-right: 0;
			}
		}
	}
}
/*===> END SALE <===*/