/*===> BEGIN SALE FORM <===*/
.sale-form{
	input{
		width: 100%;
		padding: 20px;
		border: 1px solid  #817f85;
		line-height: 18px;
	}

	button{
		width: 100%;
		max-width: 294px;
		margin-left: auto;
		margin-right: auto;
	}

	@include breakpoint(medium){
		button{
			display: block;
			margin-top: 30px;
		}
	}

	@include breakpoint(small){
		input{
			display: block;
			max-width: 450px;
			margin-left: auto;
			margin-right: auto;
			margin-bottom: 30px;
		}

		button{
			max-width: 450px;
		}
	}
}
/*===> END SALE FORM <===*/