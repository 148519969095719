/*===> BEGIN SLICK DOTS <===*/
.slick-dots{
	position: absolute;
	bottom: -35px;
	left: 50%;
	display: flex;
	margin-bottom: 0;
	transform: translateX(-50%);
	li{
		margin-bottom: 0;
		margin-right: 10px;
		&:last-child{
			margin-right: 0;
		}

		&:before{
			display: none;
		}

		&.slick-active{
			button{
				opacity: 1;
				background: $main_color;
			}
		}

		button{
			display: block;
			width: 7px;
			height: 7px;
			padding: 0;
			border: 1px solid  $main_color;
			border-radius: 50%;
			background: #fff;
			box-shadow: 0px 0px 18px 0px rgba(18, 134, 218, 0.45);
			text-indent: -999em;
			&:hover{
				opacity: 1;
				background: $main_color;
			}
		}
	}
}
/*===> END SLICK DOTS <===*/