/*===> BEGIN BLOCK FRM <===*/
.block-form-wrap{
	padding: 100px 0;
	position: relative;
	background: url(../img/form-bg.jpg) no-repeat center;
	background-size: cover;
	background-attachment: fixed;
	&:after{
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		z-index: 0;
		width: 100%;
		height: 100%;
		opacity: .9;
		@include linear-gradient(135deg, $gradient_color_from, $gradient_color_to);
	}

	h3, .h3{
		position: relative;
		z-index: 2;
		margin-bottom: 75px;
		text-align: center;
		color: #fff;
	}

	@include breakpoint(small){
		h3, .h3{
			margin-bottom: 50px;
		}
	}

	@include breakpoint(extra-small){
		padding: 50px 0;
	}
}
/*===> END BLOCK FRM <===*/