/*===> BEGIN MOBILE STRATEGY <===*/
.mobile-strategy{
	display: none;
	@include breakpoint(extra-small){
		display: block;
		max-width: 440px;
		margin-left: auto;
		margin-right: auto;
		margin-bottom: 30px;
		.front{
			display: flex;
			align-items: center;
			margin-bottom: 15px;
			.img{
				width: 55px;
				margin-right: 15px;
			}

			h5, .h5{
				margin-bottom: 0;
				font-weight: 400;
			}
		}

		.back{
			ul{
				li{
					display: flex;
					flex-direction: row;
					align-items: flex-start;
					margin-bottom: 10px;
					width: 100%;
					&:before{
						content: '';
						min-width: 7px;
						width: 7px;
						height: 7px;
						min-height: 7px;
						margin-right: 10px;
						background: #000;
						border-radius: 50%;
						transform: translateY(7px);
					}
				}
			}
		}

		.slide-arrow{
			display: flex;
			align-items: center;
			justify-content: center;
			p{
				height: 16px;
				width: 18px;
				min-width: 18px;
				margin: 0;
				cursor: pointer;
				&:first-child{
					margin-right: 10px;
				}

				&:last-child{
					margin-left: 10px;
				}

				i{
					font-size: 18px;
				}
			}
		}
	}
}
/*===> END MOBILE STRATEGY <===*/