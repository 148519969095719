/*===> BEGIN ORDER POPUP <===*/
.order-popup,
.order-rise-popup{
	position: fixed;
	z-index: 1000;
	top: 50%;
	left: 50%;
	overflow-y: auto;
	width: 100%;
	max-height: 100%;
	max-width: 560px;
	padding: 40px 100px;
	border-radius: 25px;
	transform: translate(-50%, -50%);
	transition: all .25s;
	visibility: hidden;
	opacity: 0;
	@include linear-gradient(135deg, $gradient_color_from, $gradient_color_to);
	
	&.active-popup{
		visibility: visible;
		opacity: 1;
	}

	.close-popup{
		position: absolute;
		top: 20px;
		right: 21px;
		margin: 0;
		font-size: 20px;
		font-weight: 400;
		line-height: 1em;
		color: #fff;
		cursor: pointer;
		i{
			display: block;
		}
	}

	h3, .h3{
		font-weight: 700;
		text-align: center;
		color: #fff;
	}

	.popup-order-form{
		display: flex;
		flex-direction: column;
		align-items: center;
		input{
			width: 100%;
			max-width: 360px;
			margin-bottom: 30px;
			margin-left: auto;
			margin-right: auto;
		}

		button{
			display: block;
			width: 100%;
			max-width: 360px;
			padding: 13px 20px;
			margin-left: auto;
			margin-right: auto;
			margin-top: 30px;
		}
	}

	@include breakpoint(extra-small){
		padding: 40px 50px;
	}

	@include breakpoint(mobile){
		padding: 40px 20px;
	}
}
/*===> END ORDER POPUP <===*/