/*===> BEGIN RESULT <===*/
.result-wrap{
	padding-top: 100px;
	padding-bottom: 100px;
	h2, .h2{
		margin-bottom: 100px;
		text-transform: uppercase;
		text-align: center;
	}

	.button{
		display: block;
		margin-left: auto;
		margin-right: auto;
		max-width: 360px;
	}

	@include breakpoint(extra-small){
		padding-top: 50px;
		padding-bottom: 50px;
		h2, .h2{
			margin-bottom: 50px;
		}

		.button{
			padding: 15px 20px;
		}
	}
}

.result{
	position: relative;
	width: 700px;
	height: 700px;
	border: 1px dashed  $main_color;
	border-radius: 50%;
	margin-bottom: 70px;
	margin-left: 100px;
	.phone-img{
		margin-left: -35px;
	}
	
	.inner-result{
		.result-card{
			position: absolute;
			right: 50%;
			top: 50%;
			display: flex;
			align-items: center;
			width: 100%;
			max-width: 390px;
			transform: translate(100%, -50%);
			transition: all .5s;
			
			&:nth-child(1){
				&.show-res{
					top: 3%;
					right: 43%;
					.result-desc{
						opacity: 1;
						transform: translateY(0);
						transition-delay: .55s;
					}
				}
				
			}
			&:nth-child(2){
				transition-delay: .2s;
				&.show-res{
					top: 20%;
					right: 20%;
					.result-desc{
						opacity: 1;
						transform: translateY(0);
						transition-delay: .75s;
					}
				}
			}
			&:nth-child(3){
				transition-delay: .4s;
				&.show-res{
					top: 48%;
					right: 8%;
					.result-desc{
						opacity: 1;
						transform: translateY(0);
						transition-delay: .95s;
					}
				}
			}
			&:nth-child(4){
				transition-delay: .6s;
				&.show-res{
					top: 77%;
					right: 14%;
					.result-desc{
						opacity: 1;
						transform: translateY(0);
						transition-delay: 1.15s;
					}
				}
			}
			&:nth-child(5){
				transition-delay: .8s;
				&.show-res{
					top: 95%;
					right: 38%;
					.result-desc{
						opacity: 1;
						transform: translateY(0);
						transition-delay: 1.35s;
					}
				}
			}

			.result-ico{
				display: flex;
				align-items: center;
				justify-content: center;
				width: 105px;
				min-width: 105px;
				height: 105px;
				margin-right: 20px;
				border-radius: 50%;
				box-shadow: 0px 0px 15px 0px rgba(18, 134, 218, 0.8);
				transition: all .3s;
				@include linear-gradient(135deg, $gradient_color_from, $gradient_color_to);
				&:hover{
					@include linear-gradient(185deg, $gradient_color_from, $gradient_color_to);
					box-shadow: 0px 0px 0px 0px rgba(18, 134, 218, 0.8);
				}
			}

			.result-desc{
				opacity: 0;
				transform: translateY(20px);
				transition: all .3s;
				h6, .h6{
					margin-bottom: 0;
					font-weight: 500;
				}
			}
		}
	}

	@include breakpoint(medium){
		width: 600px;
		height: 600px;
		margin-left: 30px;
		.phone-img{
			height: 600px;
			margin-top: 30px;
		}
	}

	@include breakpoint(small){
		width: 400px;
		height: 400px;
		margin-bottom: 80px;
		margin-left: 15px;
		.phone-img{
			height: 400px;
			margin-left: -20px;
		}

		.inner-result{
			.result-card{
				&:nth-child(1){
					&.show-res{
						top: 0%;
						right: 60%;
					}
				}
				&:nth-child(2){
					&.show-res{
						top: 22%;
						right: 23%;
					}
				}
				&:nth-child(3){
					&.show-res{
						top: 50%;
						right: 14%;
					}
				}
				&:nth-child(4){
					&.show-res{
						top: 80%;
						right: 21%;
					}
				}
				&:nth-child(5){
					&.show-res{
						top: 100%;
						right: 60%;
					}
				}
			}
		}
	}

	@include breakpoint(extra-small){
		width: 100%;
		height: auto;
		margin-bottom: 50px;
		margin-left: 0;
		border: none;
		border-radius: none;
		.phone-img{
			display: none;
		}

		.inner-result{
			.result-card{
				position: static;
				max-width: 400px;
				margin-left: auto;
				margin-right: auto;
				margin-bottom: 30px;
				transform: translate(0%, 0%);
			}
		}
	}

	@include breakpoint(mobile){
		.inner-result{
			.result-card{
				.result-ico{
					width: 70px;
					min-width: 70px;
					height: 70px;
					img{
						height: 40px;
					}
				}
			}
		}
	}
}
/*===> END RESULT <===*/